@import '../../styles/breakpoints';

.donationSummaryList {
  display: grid;
  gap: var(--size-spacing-03);
  margin: 0;

  @include device-medium {
    grid-template-rows: none;
    grid-template-columns: repeat(6, 1fr);
  }
}
